import React from 'react';
import Div from '../Div';
import './logolist.scss';
const partnerLogos = [
  {
    src: '/images/PNG B&W (1).png',
    alt: 'Partner',
  },
  {
    src: '/images/BA White-01.png',
    alt: 'Partner1',
  },
  {
    src: '/images/partner_3.svg',
    alt: 'Partner',
  },
  {
    src: '/images/partner_4.svg',
    alt: 'Partner',
  },
  
];

export default function LogoList() {
  return (
    <Div className="cs-partner_logo_wrap">
      {partnerLogos.map((partnerLogo, index) => (
        <div className="cs-partner_logo" key={index}>
          <img  style={{width:"6rem"}}  src={partnerLogo.src} alt={partnerLogo.alt} />
        </div>
      ))}
    </Div>
  );
}

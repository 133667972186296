import React, { useEffect } from 'react'
// import { useParams } from 'react-router-dom'
import { pageTitle } from '../../helper'
import Accordion from '../Accordion'
// import Button from '../Button'
// import Cta from '../Cta'
import IconBox from '../IconBox'
import PageHeading from '../PageHeading'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
// import TestimonialSlider from '../Slider/TestimonialSlider'
import Spacing from '../Spacing'

export default function StaffAugmentationServicePage() {
  pageTitle('Staff Augmentation Service');
  // const params = useParams();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const accordionData = [
    {
      question: 'What expertise can your team provide?',
      answer: 'We offer skilled professionals in Python, JavaScript, and Azure DevOps to fit seamlessly into your existing teams.',
    },
    {
      question: 'Is staff augmentation temporary or long-term?',
      answer: 'Both! We provide flexible staffing solutions, whether you need short-term support or long-term partnerships.',
    },
    {
      question: 'How do you vet your developers?',
      answer: 'Our developers are thoroughly vetted for technical expertise, industry experience, and cultural fit.',
    },
    {
      question: 'Can I scale my team quickly?',
      answer: 'Yes, our staff augmentation model allows you to scale your team as your project demands change.',
    },
  ];
  
  

  return (
    <>
      <PageHeading 
        title='Staff Augmentation'
        bgSrc='/images/service_hero_bg.jpeg'
        // pageLinkText={params.serviceDetailsId}
        pageLinkText='Staff Augmentation Service'
      />
      <Spacing lg='145' md='80'/>
      <Div className="container">
        <SectionHeading 
          title='Our Specializations and Capabilities' 
          subtitle='Staff Augmentation' 
          variant='cs-style1 text-center'
        />
        <Spacing lg='90' md='45'/>
        <Div className="row">
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service_icon_1.svg'
              title='Full-Stack Developer'
              subtitle='Design engaging, user-friendly interfaces in JavaScript, HTML, and CSS using frameworks like React, Angular, or Vue.js. Ensure dynamic, responsive experiences and robust backend operations with Java, Python, MySQL, or PostgreSQL.'
            />
            <Spacing lg='30' md='30'/>
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service_icon_2.svg'
              title=' Solution Architect'
              subtitle='Develop and deliver targeted technology solutions with expertise across various platforms. Seamlessly integrate systems to provide a cohesive solution that effectively addresses your unique business challenges.'
            />
            <Spacing lg='30' md='30'/>
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service_icon_3.svg'
              title='Cloud Engineers'
              subtitle='Expertise in building and maintaining your cloud infrastructure for optimal performance. Specialized in cloud platforms like AWS, Azure, or GCP, and can configure virtual machines, storage solutions, and containerization technologies for scalability and cost-efficiency.'
            />
            <Spacing lg='30' md='30'/>
          </Div>
        </Div>
      </Div>
      <Spacing lg='120' md='50'/>
      {/* related services */}
      {/* <Div className="container">
        <Div className="row align-items-center">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-radius_15 cs-shine_hover_1">
              <img src="/images/service_img_1.jpeg" alt="Service" className='cs-radius_15 w-100' />
            </Div>
            <Spacing lg='0' md='40'/>
          </Div>
          <Div className="col-lg-6 offset-xl-1">
            <h2 className="cs-font_50 cs-m0">Below our most design related services</h2>
            <Spacing lg='50' md='30'/>
            <Div className="row">
              <Div className="col-lg-6">
                <Button btnLink='/service/service-details' btnText='Web page design' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button btnLink='/service/service-details' btnText='eCommerce design' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button btnLink='/service/service-details' btnText='Landing page' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button btnLink='/service/service-details' btnText='Email template' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button btnLink='/service/service-details' btnText='Application design' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button btnLink='/service/service-details' btnText='Illustration' variant='cs-type2'/>
                <Spacing lg='0' md='10'/>
              </Div>
              <Div className="col-lg-6">
                <Button btnLink='/service/service-details' btnText='Infographic design' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button btnLink='/service/service-details' btnText='Mobile apps design' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button btnLink='/service/service-details' btnText='Banner, brochure, card' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button btnLink='/service/service-details' btnText='Other design' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div> */}
      {/* why choose our services */}
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-image_layer cs-style1">
              <Div className="cs-image_layer_in">
                <img
                  src="/images/staffaugmentation.jpg"
                  alt="About"
                  className="w-100 cs-radius_15"
                />
              </Div>
            </Div>
            <Spacing lg="0" md="40" />
          </Div>
          <Div className="col-xl-5 offset-xl-1 col-lg-6">
            <SectionHeading
              title="Staff Augmentation Services?"
              subtitle="Why Choose Our"
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
              Choose our Staff Augmentation service for personalized expertise that seamlessly integrates with your team. Our professionals specialize in cloud infrastructure, front-end design, and backend development, bringing skill sets across leading technologies like AWS, Azure, React, and Python. This approach enhances your projects with industry-leading tools and practices.
              </p>
              <Spacing lg="15" md="15" />
              <p className="cs-m0">
              We prioritize efficiency and adaptability, providing only the talent you need for your specific requirements. Our experts ensure scalable, high-performance solutions, allowing you to focus on core objectives while we handle the technical intricacies.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="0" />
            </SectionHeading>
          </Div>
        </Div>
      </Div>
      {/* <Spacing lg='150' md='80'/>
      <TestimonialSlider /> */}
      <Spacing lg='145' md='80'/>
      <Div className="container cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-5 col-lg-6">
              <SectionHeading
                title='Some pre questions and answers' 
                subtitle='Staff Augmentation FAQs'
              />
              <Spacing lg='90' md='45'/>
            </Div>
            <Div className="col-lg-6 offset-xl-1">
              <Accordion accordionData={accordionData}/>
            </Div> 
          </Div>
        </Div>
      </Div>
      <Spacing lg='150' md='80'/>
      {/* <Div className="container">
        <Cta 
          title='Let’s disscuse make <br />something <i>cool</i> together' 
          btnText='Apply For Meeting' 
          btnLink='/contact' 
          bgSrc='/images/cta_bg.jpeg'
        />
      </Div> */}
    </>
  )
}

import React, { useEffect } from 'react'
// import { useParams } from 'react-router-dom'
import { pageTitle } from '../../helper'
import Accordion from '../Accordion'
// import Button from '../Button'
// import Cta from '../Cta'
import IconBox from '../IconBox'
import PageHeading from '../PageHeading'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
// import TestimonialSlider from '../Slider/TestimonialSlider'
import Spacing from '../Spacing'

export default function WebDevelopmentServicePage() {
  pageTitle('Web Development Service');
  // const params = useParams();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const accordionData = [
  {
    question: 'What types of web applications do you develop?',
    answer:
      'We specialize in custom web apps, e-commerce platforms, and dynamic websites bespoke to meet your business objectives.',
  },
  {
    question: 'Can you handle scalable projects?',
    answer:
      'Yes, we build scalable solutions designed to grow alongside your business, ensuring long-term performance and efficiency.',
  },
  {
    question: 'Do you offer post-launch support?',
    answer:
      'Absolutely! We provide ongoing support and maintenance to ensure your web application remains optimized and secure.',
  },
  {
    question: 'What technologies do you use?',
    answer:
      ' Our tech stack includes modern frameworks like React, Node.js, and more, ensuring top-notch functionality and user experience.',
  },
];

  return (
    <>
      <PageHeading 
        title='Web Development'
        bgSrc='/images/service_hero_bg.jpeg'
        // pageLinkText={params.serviceDetailsId}
        pageLinkText='Web Development Service'
      />
      <Spacing lg='145' md='80'/>
      <Div className="container">
        <SectionHeading 
          title='Specialized Web Solutions' 
          subtitle='Web Development' 
          variant='cs-style1 text-center'
        />
        <Spacing lg='90' md='45'/>
        <Div className="row">
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service_icon_1.svg'
              title='Custom Web Apps'
              subtitle='We develop tailored web applications that align with your business needs, ensuring high performance, scalability, and seamless user experiences.'
            />
            <Spacing lg='30' md='30'/>
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service_icon_2.svg'
              title='E-commerce Solution'
              subtitle='Build powerful e-commerce platforms with secure payment systems, inventory management, and user-friendly interfaces to enhance your online business success.'
            />
            <Spacing lg='30' md='30'/>
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service_icon_3.svg'
              title='UI/UX and Architecture'
              subtitle='Our team creates visually engaging, intuitive user interfaces and robust, scalable architectures for optimized user experience and system performance.'
            />
            <Spacing lg='30' md='30'/>
          </Div>
        </Div>
      </Div>
      <Spacing lg='120' md='50'/>
      {/* related services */}
      {/* <Div className="container">
        <Div className="row align-items-center">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-radius_15 cs-shine_hover_1">
              <img src="/images/service_img_1.jpeg" alt="Service" className='cs-radius_15 w-100' />
            </Div>
            <Spacing lg='0' md='40'/>
          </Div>
          <Div className="col-lg-6 offset-xl-1">
            <h2 className="cs-font_50 cs-m0">Below our most design related services</h2>
            <Spacing lg='50' md='30'/>
            <Div className="row">
              <Div className="col-lg-6">
                <Button btnLink='/service/service-details' btnText='Web page design' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button btnLink='/service/service-details' btnText='eCommerce design' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button btnLink='/service/service-details' btnText='Landing page' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button btnLink='/service/service-details' btnText='Email template' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button btnLink='/service/service-details' btnText='Application design' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button btnLink='/service/service-details' btnText='Illustration' variant='cs-type2'/>
                <Spacing lg='0' md='10'/>
              </Div>
              <Div className="col-lg-6">
                <Button btnLink='/service/service-details' btnText='Infographic design' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button btnLink='/service/service-details' btnText='Mobile apps design' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button btnLink='/service/service-details' btnText='Banner, brochure, card' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button btnLink='/service/service-details' btnText='Other design' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div> */}
      {/* why choose our services */}
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-image_layer cs-style1">
              <Div className="cs-image_layer_in">
                <img
                  src="/images/service_4.jpeg"
                  alt="About"
                  className="w-100 cs-radius_15"
                />
              </Div>
            </Div>
            <Spacing lg="0" md="40" />
          </Div>
          <Div className="col-xl-5 offset-xl-1 col-lg-6">
            <SectionHeading
              title="Web Development Services?"
              subtitle="Why Choose Our"
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
              Our web development team specializes in crafting scalable, high-performing custom web solutions personalized to your business needs. We focus on delivering intuitive user interfaces and solid back-end architectures to ensure seamless functionality and user satisfaction.
              </p>
              <Spacing lg="15" md="15" />
              <p className="cs-m0">
              With extensive experience in e-commerce solutions, we help businesses grow by developing secure, robust platforms. Whether you're a startup or an established business, our commitment to excellence ensures that your web application is future-proof and scalable.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="0" />
            </SectionHeading>
          </Div>
        </Div>
      </Div>
      {/* <Spacing lg='150' md='80'/>
      <TestimonialSlider /> */}
      <Spacing lg='145' md='80'/>
      <Div className="container cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-5 col-lg-6">
              <SectionHeading
                title='Some pre questions and answers' 
                subtitle='Web Development FAQs'
              />
              <Spacing lg='90' md='45'/>
            </Div>
            <Div className="col-lg-6 offset-xl-1">
              <Accordion accordionData={accordionData}/>
            </Div> 
          </Div>
        </Div>
      </Div>
      <Spacing lg='150' md='80'/>
      {/* <Div className="container">
        <Cta 
          title='Let’s disscuse make <br />something <i>cool</i> together' 
          btnText='Apply For Meeting' 
          btnLink='/contact' 
          bgSrc='/images/cta_bg.jpeg'
        />
      </Div> */}
    </>
  )
}

import React, { useEffect } from 'react'
// import { useParams } from 'react-router-dom'
import { pageTitle } from '../../helper'
import Accordion from '../Accordion'
// import Button from '../Button'
// import Cta from '../Cta'
import IconBox from '../IconBox'
import PageHeading from '../PageHeading'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
// import TestimonialSlider from '../Slider/TestimonialSlider'
import Spacing from '../Spacing'

export default function MobileDevelopmentServicePage() {
  pageTitle('Mobile Development Service');
  // const params = useParams();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const accordionData = [
    {
      question: 'What platforms do you develop mobile apps for?',
      answer: 'We create apps for iOS, Android, and hybrid platforms, ensuring maximum reach across devices.',
    },
    {
      question: 'Do you offer end-to-end app development?',
      answer: 'Yes, from concept to deployment, we handle everything including design, development, testing, and maintenance.',
    },
    {
      question: 'How do you ensure app quality?',
      answer: 'Our apps undergo rigorous testing to ensure smooth performance, security, and seamless user experience.',
    },
    {
      question: 'Can you integrate third-party services into my app?',
      answer: 'Definitely! We can integrate APIs, payment gateways, and other third-party services to enhance app functionality.',
    },
  ];
  

  return (
    <>
      <PageHeading 
        title='Mobile Development'
        bgSrc='/images/service_hero_bg.jpeg'
        // pageLinkText={params.serviceDetailsId}
        pageLinkText='Mobile Development Service'
      />
      <Spacing lg='145' md='80'/>
      <Div className="container">
        <SectionHeading 
          title='Innovative Mobile Experiences' 
          subtitle='Mobile Development' 
          variant='cs-style1 text-center'
        />
        <Spacing lg='90' md='45'/>
        <Div className="row">
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service_icon_1.svg'
              title='IOS App Development'
              subtitle=' We build feature-rich, high-performing iOS apps designed to engage users and meet your business goals, optimized for Apple’s ecosystem.'
            />
            <Spacing lg='30' md='30'/>
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service_icon_2.svg'
              title='Android App Development'
              subtitle='Develop scalable, responsive Android apps with rich features, ensuring optimal performance across a wide range of devices and user demographics.'
            />
            <Spacing lg='30' md='30'/>
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service_icon_3.svg'
              title='Hybrid App Development'
              subtitle=' Create cost-effective, cross-platform mobile apps that deliver a native-like experience, seamlessly operating on both iOS and Android platforms.'
            />
            <Spacing lg='30' md='30'/>
          </Div>
        </Div>
      </Div>
      <Spacing lg='120' md='50'/>
      {/* related services */}
      {/* <Div className="container">
        <Div className="row align-items-center">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-radius_15 cs-shine_hover_1">
              <img src="/images/service_img_1.jpeg" alt="Service" className='cs-radius_15 w-100' />
            </Div>
            <Spacing lg='0' md='40'/>
          </Div>
          <Div className="col-lg-6 offset-xl-1">
            <h2 className="cs-font_50 cs-m0">Below our most design related services</h2>
            <Spacing lg='50' md='30'/>
            <Div className="row">
              <Div className="col-lg-6">
                <Button btnLink='/service/service-details' btnText='Web page design' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button btnLink='/service/service-details' btnText='eCommerce design' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button btnLink='/service/service-details' btnText='Landing page' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button btnLink='/service/service-details' btnText='Email template' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button btnLink='/service/service-details' btnText='Application design' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button btnLink='/service/service-details' btnText='Illustration' variant='cs-type2'/>
                <Spacing lg='0' md='10'/>
              </Div>
              <Div className="col-lg-6">
                <Button btnLink='/service/service-details' btnText='Infographic design' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button btnLink='/service/service-details' btnText='Mobile apps design' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button btnLink='/service/service-details' btnText='Banner, brochure, card' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button btnLink='/service/service-details' btnText='Other design' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div> */}
      {/* why choose our services */}
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-image_layer cs-style1">
              <Div className="cs-image_layer_in">
                <img
                  src="/images/service_8.jpeg"
                  alt="About"
                  className="w-100 cs-radius_15"
                />
              </Div>
            </Div>
            <Spacing lg="0" md="40" />
          </Div>
          <Div className="col-xl-5 offset-xl-1 col-lg-6">
            <SectionHeading
              title="Mobile App Development Services?"
              subtitle="Why Choose Our"
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
              We offer end-to-end mobile app development services, creating dynamic, high-quality apps for both iOS and Android. Our experts understand the intricacies of mobile platforms, ensuring your app performs flawlessly on any device, and providing a superior user experience.
              </p>
              <Spacing lg="15" md="15" />
              <p className="cs-m0">
              Our hybrid app development solutions are cost-effective and ensure maximum coverage across platforms. By partnering with us, you gain a dedicated team that focuses on delivering apps that are both innovative and functional.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="0" />
            </SectionHeading>
          </Div>
        </Div>
      </Div>
      {/* <Spacing lg='150' md='80'/>
      <TestimonialSlider /> */}
      <Spacing lg='145' md='80'/>
      <Div className="container cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-5 col-lg-6">
              <SectionHeading
                title='Some pre questions and answers' 
                subtitle='Mobile App Development FAQs'
              />
              <Spacing lg='90' md='45'/>
            </Div>
            <Div className="col-lg-6 offset-xl-1">
              <Accordion accordionData={accordionData}/>
            </Div> 
          </Div>
        </Div>
      </Div>
      <Spacing lg='150' md='80'/>
      {/* <Div className="container">
        <Cta 
          title='Let’s disscuse make <br />something <i>cool</i> together' 
          btnText='Apply For Meeting' 
          btnLink='/contact' 
          bgSrc='/images/cta_bg.jpeg'
        />
      </Div> */}
    </>
  )
}
